<template>
  <div class="interactive__pdfs">
    <SubrouteBar :title="title" />
    <div class="cards">
      <Card v-for="feature in features" 
        :key="feature.key" :id="feature.key" :feature="feature" 
        v-on:update-active="update($event)"
        v-on:contact="contact($event)"
      />
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";
import SubrouteBar from "@/components/digital-presentations/below/subroute-bar/SubrouteBar.vue";

export default {
  components: {
    SubrouteBar,
    Card
  },
  metaInfo: {
    title: "View Awesome Interactive PDF Examples for Hotels",
    meta: [
      {
        vmid: "description",
        name: "description",
        // content: `
        //   Interactive PDFs add a layer of dynamism & interactivity to fixed-layout PDFs. 
        //   Empower your clients & team to easily find the information they need and share it on web with a simple link.
        // `
        content: `
          Show off unique aspects of your property with interactive sales pieces. 
          Interactive PDFs add a layer of dynamism to fixed-layout PDFs & are shared with a URL.
        `
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/digital-media/interactive-pdf"
      }
    ]
  },
  data() {
    return {
      title: "INTERACTIVE PDF",
      features: {
        RM: {
          key: "RM",
          active: true,
          title: "Regional Map",
          frame:
            "https://indd.adobe.com/view/38ca533e-e39a-4301-822f-dbe5a126daf5"
        },
        SP: {
          key: "SP",
          active: false,
          title: "Single Property",
          frame:
            "https://indd.adobe.com/view/89bfdf16-b51f-480f-b970-92e68beb1257"
        },
        MF: {
          key: "MF",
          active: false,
          title: "Floorplan",
          frame:
            "https://indd.adobe.com/view/bf5c30b6-32d1-448d-a195-def7d6c08f68"
        }
      }
    };
  },
  methods: {
    update(key) {
      this.resetActive();
      this.features[key].active = true;
    },
    resetActive() {
      Object.keys(this.features).forEach(
        k => (this.features[k].active = false)
      );
    },
    contact(spec) {
      this.$emit("contact", spec);
    }
  }
};
</script>

<style scoped>
.cards {
  background: #e3e3e3;
  padding: 1.5rem 0;
}

@media screen and (max-width: 768px) {
  .cards {
    padding: 0.25rem 0;
  }
}
</style>
