<template>
  <div
    class="card"
    :class="{ active: feature.active, inactive: !feature.active }"
  >
    <div class="title__container" @click="update()">
      <h3 class="title standout">{{ feature.title }}</h3>
    </div>
    <div class="iframe__container">
      <iframe
        :src="feature.frame"
        frameborder="0"
        v-if="feature.active"
      ></iframe>
    </div>
    <div class="button__container">
      <!-- <button class="order__button normal" @click="contact">ORDER NOW</button> -->
      <a
        target="_blank"
        href="https://creator.zohopublic.com/jerideatherage1/product-catalog/report-perma/Add_Digital_Collateral_Product_Report/sadxSmg7rMqJUKFKxGp28SkJU7epmD2BnzYMPXAHA6rjCOTw4mCMaTXW6Y1zjdH2VkxVgkPg0vrz1gAMh3hTwRX3rqYtQ5aBgxSW"
        class="order__button normal"
        >ORDER NOW</a
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feature: {
      type: Object,
      required: true
    }
  },
  methods: {
    update() {
      this.$emit("update-active", this.feature.key);
    },
    contact() {
      this.$emit("contact", "interactive-pdf");
    }
  }
};
</script>

<style scoped>
.card {
  width: 80%;
  margin: 1.5rem auto;
  background: #fffefe;
  border: 1px solid #707070;
  box-shadow: 0px 6px 15px #314657;
}

.title__container {
  text-align: center;
  /* background: #f5a382; */
  background: var(--ath-orange);
}

.title__container:hover {
  cursor: pointer;
}

.title {
  color: #314657;
  margin: 0;
  font-size: 2.5vw;
}

.iframe__container {
  margin: 3rem auto 0 auto;
  position: relative;
  width: 95%;
  padding-bottom: 56.25%;

  transition: all 1s;
}

.iframe__container > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.button__container {
  width: 100%;
  padding: 3rem;
  display: flex;
  justify-content: center;

  transition: all 1s;
}

.order__button {
  color: #ee6630;
  background: transparent;
  border: 1px solid #707070;
  padding: 1rem 5rem;
  font-size: 1vw;
  transition: all 1s;
}

.card.inactive > .iframe__container {
  margin: 0 auto;
  padding: 0;
}

.card.inactive > .button__container {
  height: 0;
  padding: 0;
}

.card.inactive > .button__container > .order__button {
  display: none;
}

@media screen and (max-width: 1024px) {
  .card {
    width: 90%;
  }

  .iframe__container {
    margin-top: 1rem;
  }

  .button__container {
    padding: 1rem;
  }

  .order__button {
    font-size: 2vw;
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 5vw;
  }
  .order__button {
    font-size: 3vw;
    padding: 0.5rem;
  }
}
</style>
