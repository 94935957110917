<template>
  <div class="ebrochures">
    <SubrouteBar :title="title" />
    <div class="card">
      <div class="left">
        <iframe
          src="https://myhotelbrochure.com/courtyard-roseville-web-example.html"
          frameborder="0"
        ></iframe>
      </div>
      <div class="right">
        <div class="content-top">
          <p class="normal">
            Bring your print collateral into the digital age with our custom
            designed eBrochures. This interactive PDF gives the impression of
            browsing through an actual multi-page booklet.
          </p>
        </div>
        <div class="content-bottom">
          <p class="list-title normal">
            Custom eBrochure with Page Flip features
          </p>
          <ul>
            <li
              class="list-item normal"
              v-for="(item, index) in items"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="bottom">
        <a
          class="btn normal"
          href="https://allthingshospitality.com/examples/"
          rel="noopener noreferrer"
          target="_blank"
          @click="exampleClick"
          >LIVE EXAMPLES</a
        >
        <!-- <button class="btn normal" @click="contact">ORDER NOW</button> -->
        <a
          href="https://creator.zohopublic.com/jerideatherage1/product-catalog/report-perma/Add_Digital_Collateral_Product_Report/sadxSmg7rMqJUKFKxGp28SkJU7epmD2BnzYMPXAHA6rjCOTw4mCMaTXW6Y1zjdH2VkxVgkPg0vrz1gAMh3hTwRX3rqYtQ5aBgxSW"
          target="_blank"
          class="btn normal"
        >
          ORDER NOW
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import SubrouteBar from "@/components/digital-presentations/below/subroute-bar/SubrouteBar.vue";

export default {
  components: { SubrouteBar },
  methods: {
    contact() {
      this.$emit("contact", "ebrochure");
    },
    exampleClick() {
      this.$ga.event(
        "ebrochure_example_navigation",
        "click",
        "ebrochure_example_navigation"
      );
    }
  },
  data() {
    return {
      title: "CUSTOM eBROCHURE WITH PAGE FLIP",
      items: [
        "Custom design tailored to your hotel",
        "Page flip for a smooth browsing experience",
        "Downloadable and printable",
        "Social share features",
        "Zoom and layout options to suit your client's browsing habits",
        "Four page minimum, no maximum page limit",
        "Hosted for one year at no charge, making it easy to share with a simple link"
      ]
    };
  },
  metaInfo: {
    title: "Digital eBrochure | Hotel Digital Marketing",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Take your brochures to the next level by bringing them to the digital market. Our eBrochures come with one year of hosting, a vanity URL, and include a page turning animation that is sure to delight your viewers."
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/digital-media/ebrochure"
      }
    ]
  }
};
</script>

<style scoped>
.ebrochures {
  padding-bottom: 2rem;
}

.card {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 4rem);
  margin: 2rem auto 0 auto;
  background: white;
  transition: box-shadow 0.2s ease-in-out;
}

.card:hover {
  box-shadow: 0px 6px 15px #314657;
}

.left {
  position: relative;
  width: 50%;
  padding-bottom: 28.125%;
}

.left > iframe {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: calc(100% - 4rem);
  height: calc(100% - 4rem);
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
}

.content-top > p {
  font-size: 1.25vw;
  color: var(--ath-blue);
}

.content-bottom {
  font-size: 1vw;
}

.list-title {
  color: var(--ath-blue);
  text-decoration: underline;
}

.list-item {
  color: var(--ath-blue);
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-top: 2rem;
  padding-bottom: 6.5rem;
}

.btn {
  font-size: 1.25vw;
  color: var(--ath-orange);
  width: 20%;
  padding: 1rem 0;
  border: 1px solid var(--ath-blue);
  background: var(--whole-background);
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .left,
  .right {
    width: 100%;
    padding: 0 2rem;
  }

  .left {
    padding-bottom: 56.25%;
  }

  .right p {
    font-size: 3vw;
  }

  .content-bottom {
    font-size: 2.5vw;
  }

  .btn {
    width: 40%;
    font-size: 2.5vw;
  }

  .bottom {
    padding-bottom: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .left {
    padding-bottom: 112.5%;
    padding-left: 0;
    padding-right: 0;
  }

  .left > iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
