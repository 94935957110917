<template>
  <div class="reveal-sliders">
    <script type="application/ld+json">
      {
        "@context": "http://schema.org",
        "@type": "Service",
        "name": "Reveal Slider Web Page",
        "image": "https://allthingshospitality.com/images-used-for-schema/Reveal-Slider-Image.PNG",
        "description": "Reveal sliders are the perfect way to show before and after images or to compare the difference between similar options. We are able to embed sliders onto custom landing pages that maintain brand identity and requirements. This also allows you to add hyper links to other sales pieces or booking engines.",
        "category": "Digital Media Service"
      }
    </script>
    <SubrouteBar :title="title" />
    <div class="card">
      <div class="left">
        <iframe
          src="https://allthingshospitality.com/iframe"
          frameborder="0"
        ></iframe>
      </div>
      <div class="right">
        <div class="content-top">
          <p>
            Reveal sliders are the perfect way to show before and after images
            or to compare the difference between similar options. We are able to
            embed sliders onto custom landing pages that maintain brand identity
            and requirements. This also allows you to add hyper links to other
            sales pieces or booking engines.
          </p>
        </div>
        <div class="content-bottom">
          <div class="list-section" v-for="(list, index) in lists" :key="index">
            <p class="list-title normal">{{ list.title }}</p>
            <ul>
              <li
                class="list-item normal"
                v-for="(item, index) in list.items"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bottom">
        <a
          class="btn normal"
          href="https://allthingshospitality.com/reveal/"
          rel="noopener noreferrer"
          target="_blank"
          @click="exampleClick"
          >PAGE EXAMPLES</a
        >
        <!-- <button class="btn normal" @click="contact">ORDER NOW</button> -->
        <a
          target="_blank"
          href="https://creator.zohopublic.com/jerideatherage1/product-catalog/report-perma/Add_Digital_Collateral_Product_Report/sadxSmg7rMqJUKFKxGp28SkJU7epmD2BnzYMPXAHA6rjCOTw4mCMaTXW6Y1zjdH2VkxVgkPg0vrz1gAMh3hTwRX3rqYtQ5aBgxSW"
          class="btn normal"
          >ORDER NOW</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import SubrouteBar from "@/components/digital-presentations/below/subroute-bar/SubrouteBar.vue";

export default {
  components: { SubrouteBar },
  methods: {
    contact() {
      this.$emit("contact", "reveal-sliders");
    },
    exampleClick() {
      this.$ga.event(
        "reveal_example_navigation",
        "click",
        "reveal_example_navigation"
      );
    }
  },
  data() {
    return {
      title: "REVEAL SLIDERS",
      lists: [
        {
          title: "Hotel Reveal Landing Page Features",
          items: [
            "Multiple layouts for single or multiple property image sliders",
            "Welcome paragraph to communicate your hotel's main message",
            "Features/benefits bullets",
            "Contact information",
            "Fast deployment",
            "Hosted on ATH servers, hotel vanity site not required",
            "Affordable hotel digital marketing solution - priced from $199"
          ]
        },
        {
          title: "Suggested Uses",
          items: [
            "Hotel renovation marketing",
            "Hotel rebranding marketing",
            "Hotel grand openings",
            "Digital sale offers or coupons",
            "Link from email campaigns, email signatures, and social media campaigns"
          ]
        }
      ]
    };
  },
  metaInfo: {
    title: "Reveal Slider Web App | Digital Renovation Announcement",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "The best way to announce your renovation or re-branding. Reveal sliders come with one year of hosting, a vanity URL, and an interactive way of displaying before/after images. Also include new information about your property!"
      }
    ],
    link: [
      {
        vmid: "canonical",
        rel: "canonical",
        href: "https://allthingshospitality.com/digital-media/reveal-slider"
      }
    ]
  }
};
</script>

<style scoped>
.reveal-sliders {
  padding-bottom: 2rem;
}
.card {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 4rem);
  margin: 2rem auto 0 auto;
  background: white;
  transition: box-shadow 0.2s ease-in-out;
}

.card:hover {
  box-shadow: 0px 6px 15px #314657;
}

.left {
  position: relative;
  width: 50%;
  padding-bottom: 28.125%;
  align-self: center;
}

.left > iframe {
  position: absolute;
  top: 2rem;
  left: 2rem;
  width: calc(100% - 4rem);
  height: calc(100% - 4rem);
}

.right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;
  align-self: center;
}

.content-top > p {
  font-size: 1.25vw;
  color: var(--ath-blue);
}

.content-bottom {
  font-size: 1vw;
  display: flex;
}

.list-section {
  width: 50%;
}

.list-title {
  color: var(--ath-blue);
  text-decoration: underline;
}

.list-item {
  color: var(--ath-blue);
}

.bottom {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  padding-top: 2rem;
  padding-bottom: 6.5rem;
}

.btn {
  font-size: 1.25vw;
  color: var(--ath-orange);
  width: 20%;
  padding: 1rem 0;
  border: 1px solid var(--ath-blue);
  background: var(--whole-background);
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 1024px) {
  .card {
    justify-content: center;
  }
  .left,
  .right {
    width: 100%;
  }

  .left {
    padding-bottom: 84.375%;
  }
  .right p {
    font-size: 3vw;
    margin-top: 0;
  }

  .content-bottom {
    font-size: 2vw;
  }

  .list-section {
    padding: 0 0.5rem;
  }

  .bottom {
    padding: 1.5rem 0;
  }

  .btn {
    width: 40%;
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 817px) {
  .left {
    padding-bottom: 100%;
    margin-bottom: 1rem;
  }
  .left > iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .right {
    /* padding: 0 0.5rem; */
    padding: 0 2rem;
  }
  .content-bottom {
    flex-wrap: wrap;
  }
  .right p {
    font-size: 4vw;
  }
  .list-section {
    width: 100%;
    font-size: 3vw;
  }
}
</style>
