<template>
  <div id="digital-presentations-btf">
    <div id="digital-presentations-landing">
      <LandingCard v-for="card in cards" :key="card.key" :info="card.info"/>
    </div>
    <transition name="fade" mode="out-in">
      <router-view v-on:contact="contact($event)"></router-view>
    </transition>
  </div>
</template>

<script>
import LandingCard from "@/components/digital-presentations/below/landing-card/LandingCard.vue";
import PATH from "@/paths";

export default {
  components: { LandingCard },
  methods: {
    contact(spec) {
      this.$emit("contact", spec);
    }
  },
  data() {
    return {
      cards: [
        {
          key: "IPDF",
          info: {
            title: "Interactive PDFs",
            content:
              "Enhance your digital marketing strategy with cutting edge technology.",
            url: PATH.INTERACTIVE.PDF
          }
        },
        {
          key: "RS",
          info: {
            title: "Reveal Sliders",
            content:
              "Reveal sliders are the perfect way to announce changes to your property.",
            url: PATH.INTERACTIVE.REVEAL
          }
        },
        {
          key: "CE",
          info: {
            title: "Custom eBrochures",
            content:
              "Custom eBrochures are a simple solution for digital marketing.",
            url: PATH.INTERACTIVE.EBROCHURE
          }
        }
      ]
    };
  }
};
</script>

<style scoped>
#digital-presentations-btf {
  background: var(--whole-background);
}

#digital-presentations-landing {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 0 3rem 0;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

@media screen and (max-width: 1024px) {
  #digital-presentations-landing {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  #digital-presentations-landing {
    padding: 0;
  }
}
</style>
