<template>
  <div class="card">
    <h3 class="title uppercase standout">{{ info.title }}</h3>
    <hr class="line">
    <div class="wrapper">
      <p class="content normal">{{ info.content }}</p>
    </div>
    <router-link :to="`${url.parent}/${info.url}${url.hash}`" tag="button" class="btn normal">Learn More</router-link>
  </div>
</template>

<script>
import PATH from "@/paths";

export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      url: {
        parent: PATH.INTERACTIVE.DEFAULT,
        hash: "#information"
      }
    };
  }
};
</script>


<style scoped>
.card {
  width: 30%;
  background: white;
  border: 4px solid var(--ath-blue);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  color: var(--ath-blue);
  padding: 0 2rem;
}

.title {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 2vw;
}

.line {
  width: 100%;
}

.wrapper {
  padding: 0 0.5rem;
}

.content {
  margin-top: 0;
  font-size: 1.25vw;
}

.btn {
  color: var(--ath-orange);
  font-size: 1.25vw;
  background: white;
  border: none;
  box-shadow: 0px 2px 6px var(--ath-blue);
  padding: 0.5rem 2.5rem;
  margin-bottom: 1rem;
  transition: color 0.2s;
}

.btn:hover {
  color: var(--ath-blue);
}

@media screen and (max-width: 1024px) {
  .card {
    width: 90%;
    margin: 2rem 0;
  }

  .title {
    font-size: 4vw;
  }

  .content {
    font-size: 3vw;
  }

  .btn {
    font-size: 3vw;
  }
}
</style>
